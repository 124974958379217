/* You can add global styles to this file, and also import other style files */
@use '@nationwide-bolt/css-core/dist/css/bolt-core';
@import '@nationwide-bolt/adapter-material/dist/bolt-material-theme.css';
@import '@nationwide-bolt/adapter-material/dist/bolt-material-theme';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.float-child {
  float: left;
  margin-right: 50px;
}

.drop-list {
  width: 500px;
  max-width: 100%;
  min-height: 60px;
  display: block;
  overflow: hidden;
  background: #e8e9eaff;
}

.drop-box {
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  font-size: 12px;
  height: 20px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px #707070ff,
    0 8px 10px 1px #707070ff,
    0 3px 14px 2px #707070ff;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-box:last-child {
  border: none;
}

.drop-list.cdk-drop-list-dragging .drop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.p {
  margin-bottom: 24px;
}

.h3 {
  margin-bottom: 24px;
}

.bolt-body-copy-lg {
  margin-bottom: 24px;
}

.inner-box bolt-icon.icon {
  visibility: hidden;
}

.inner-box:hover bolt-icon.icon {
  visibility: visible;
  float: right;
}

.inner-box:hover {
  padding: 0.25em;
  box-shadow: inset 0.13em 0em #0047bbff;
  background: #dcdfdfff;
}

.inner-box {
  width: 100%;
}

.policies .mat-select-content {
  background-color: white !important;
}

.highlighted {
  background-color: #8cc8e9ff;
  width: fit-content;
}

.bolt-body-copy-md {
  margin-bottom: 10px;
}

.headers {
  display: flex;
  justify-content: space-between;
}
